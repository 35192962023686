<template>
    <supportLayout>
        <div class="p-reports full_page">
            <div class="container">

                <div class="coming_soon">
                    <h3>Under construction</h3>
                </div>
            </div>
        </div>
    </supportLayout>

</template>

<script>
import supportLayout from "@/layouts/supportLayout.vue";
export default {
    name: "reports",
    components: {
        supportLayout
    }
}
</script>

<style scoped>

</style>